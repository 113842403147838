import React from 'react';
import { FieldComponent } from '../ContentEditor';
import Grid from '@material-ui/core/Grid';
import DateTimeFieldFormField from '@/form-fields/DateTimePicker';
import { Field } from 'react-final-form';

const DateTimeField: FieldComponent = ({ title, name, value, onClick }) => (
  <Field
    fullWidth
    name={name}
    label={title}
    onClick={onClick}
    value={value || ''}
    variant="filled"
    pickerFormat="dd/MM/yyyy hh:mm a"
    component={DateTimeFieldFormField}
    InputProps={{ disableUnderline: true }}
    InputLabelProps={{ shrink: true }}
  />
);

export default DateTimeField;
