import React from 'react'
import idx from 'idx'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import {
  GetContentReference as GetContentReferenceType,
  GetContentReferenceVariables,
} from '@/types/generated/GetContentReference';

import ReferencePreview from './ReferencePreview'

const GetContentReference = gql`
  query GetContentReference($contentIds: [String!]) {
    viewer {
      content(contentIds: $contentIds) {
        edges {
          node {
            id
            contentId
            contentType
            repository
            draft
          }
        }
      }
    }
  }
`;

const ReferenceView: React.FC<{ contentId: string; schema: any, locale?: string | undefined }> = props => {
  const { data, loading } = useQuery<GetContentReferenceType, GetContentReferenceVariables>(
    GetContentReference,
    {
      variables: {
        contentIds: [props.contentId],
      },
    }
  );

  const node = idx(data, _ => _.viewer.content.edges[0].node);

  if (!node || loading) {
    return 'Loading...';
  }

  return <ReferencePreview locale={props.locale} node={node} schema={props.schema} />;
};

export default ReferenceView
