import I18NISOCountries from '@/helpers/I18NISOCountries';
import flag from 'country-code-emoji';
import { PLACEHOLDER } from '@/constants';

const CC_REGEX = /^[a-z]{2}$/i;

export default function formatCountryCode(code?: string | null): string {
  if (!code) {
    return PLACEHOLDER;
  }
  
  return `${CC_REGEX.test(code) ? flag(code) : ''}${I18NISOCountries.getName(code, 'en') || code}`;
}
