import React, { FC, createContext, useState } from 'react';

const initialState = {
  isFocused: false,
  setIsFocused: (_: boolean) => {},
};

export type AppState = typeof initialState;

const EditorContext = createContext(initialState);

export const EditorContextProvider: FC = props => {
  const [isFocused, setIsFocused] = useState(initialState.isFocused);

  const context = {
    isFocused,
    setIsFocused,
  };

  return <EditorContext.Provider value={context}>{props.children}</EditorContext.Provider>;
};

export default EditorContext;
