import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import FormControl from '@material-ui/core/FormControl'
import { Theme } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    zIndex: 1,
    margin: theme.spacing(1, 0),
    // padding: theme.spacing(1, 1, 1, 0),
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255,255,255,0.08)'
  },
  root_focused: {
    backgroundColor: theme.palette.background.paper
  },
  grid: {
    display: 'flex',
    marginTop: 20,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(0, 0.5)
  },
  children: {
    flex: 1
  }
}), { name: 'ContainerPaper' })

const ContainerPaper = (props, ref) => {
  const {
    label,
    focused,
    children,
    onDelete,
    onMoveUp,
    className,
    onMoveDown,
    isSelected,
    onDragClick,
    ...other
  } = props

  const classes = useStyles(props)

  return (
    <Paper
      ref={ref}
      square
      className={clsx(classes.root, focused && classes.root_focused, className)}
      {...other}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '4px 4px 4px 2px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <IconButton size='small' style={{ padding: 0 }} onClick={onMoveUp}>
              <KeyboardArrowUpIcon fontSize='small' />
            </IconButton>
          </div>
          <div>
            <IconButton disabled size='small' style={{ padding: 0 }} onClick={onDragClick}>
              <DragIndicatorIcon fontSize='small' />
            </IconButton>
          </div>
          <div>
            <IconButton size='small' style={{ padding: 0 }} onClick={onMoveDown}>
              <KeyboardArrowDownIcon fontSize='small' />
            </IconButton>
          </div>
        </div>
        <div style={{ padding: '8px 8px 8px 4px', flex: 1 }}>
          <FormControl fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            <div className={classes.grid}>
              <div className={classes.children}>
                {children}
              </div>
              <div>
                <IconButton size='small' onClick={onDelete}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </div>
            </div>
          </FormControl>
        </div>
      </div>
    </Paper>
  )
}

export default forwardRef(ContainerPaper)
