import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

const NumberFieldFormField: FC<FieldRenderProps<BigInteger, HTMLInputElement>> = props => {
  const { meta, input, ...other } = props;
  const { name, onChange, value, ...otherInput } = input;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <TextField
      {...other}
      name={name}
      error={showError}
      type="number"
      value={value}
      onChange={onChange}
      helperText={showError ? meta.error || meta.submitError : undefined}
      inputProps={otherInput as InputBaseComponentProps}
    />
  );
};

export default NumberFieldFormField;
