import React, { FC } from 'react';
import SearchBox from './SearchBox';
import { Location } from '@reach/router';

const AppSearch: FC = props => {
  return (
    <Location>
      {({ location, navigate }) => {
        const onChange = (id: string) => {
          const parts = location.pathname.match(/^\/players\/[^/]+\/?(.+)?/);
          const url = parts && parts[1] ? `/players/${id}/${parts[1]}` : `/players/${id}/overview`;

          navigate(url);
        };

        return <SearchBox {...props} onChange={onChange} />;
      }}
    </Location>
  );
};

export default AppSearch;
