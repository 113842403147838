import global from './global';
import refuelcasino from './refuelcasino';
import mountgold from './mountgold';
import amokcasino from './amokcasino';
import raptorcasino from './raptorcasino';
import ref from './ref';
import affiliates from './affiliates';
import dinotech from './dinotech';
import infiniza from './infiniza';
import tekzia from './tekzia';
import mountgoldCom from './mountgoldCom';
import mountgoldEu from './mountgoldEu';
import mountgoldIo from './mountgoldIo';
import { Repository } from '../types';

const schema: Repository[] = [
  global,
  refuelcasino,
  mountgold,
  amokcasino,
  raptorcasino,
  ref,
  affiliates,
  dinotech,
  infiniza,
  tekzia,
  mountgoldCom,
  mountgoldEu,
  mountgoldIo,
];

export default schema;
