import React, { useContext } from 'react';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/styles';

import { clients, useClient } from '@/clients';

import SessionContext from '@/contexts/SessionContext';

import AppSearch from './AppSearch';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      grow: {
        [theme.breakpoints.up('sm')]: {
          flexGrow: 1,
        },
      },
      appSearch: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      divider: {
        width: 1,
        height: theme.spacing(3),
        background: '#ebebf2',
        margin: theme.spacing(0, 1.5, 0, 1.5),
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      marginBox: {
        margin: theme.spacing(0, 1.5, 0, 1.5),
      },
      toolbar: {
        paddingLeft: 16,
      },
      Badge__badge: {
        backgroundColor: '#ff2c65',
      },
      select: {
        marginTop: 0,
        minWidth: 100,
      },
      clientSelect: {},
    }),
  { name: 'AppNavBar' }
);

interface PropTypes {
  onToggleSidebar: () => void;
  orgs: any;
}

const AppNavBar = (props: PropTypes) => {
  const { onToggleSidebar, orgs } = props;

  const allowedClients = clients.filter((elem) => {
    return orgs ? orgs.includes(elem.org) : false;
  });

  const theme: Theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const { logout, user } = useContext(SessionContext);
  const { id, org, setClientId } = useClient();

  if (org && orgs && !orgs.includes(org)) {
    setClientId(allowedClients[0]?.id);
  }

  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const notificationsCount = 0;

  const desktopMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem disabled>
        <IconButton disabled color="inherit">
          <LiveHelpOutlinedIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem disabled>
        <IconButton disabled color="inherit">
          <Badge badgeContent={notificationsCount} classes={{ badge: classes.Badge__badge }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton color="inherit">
          <AccountCircleIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={logout}>
        <IconButton color="inherit">
          <PowerSettingsNewIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={onToggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <AppSearch className={classes.appSearch} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton disabled color="inherit">
              <LiveHelpOutlinedIcon />
            </IconButton>
            <IconButton disabled color="inherit">
              <Badge
                badgeContent={!!notificationsCount ? notificationsCount : null}
                classes={{ badge: classes.Badge__badge }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <div className={classes.divider} />
            {allowedClients.length > 1 ? (
              <Select
                value={id}
                variant="outlined"
                style={{
                  minWidth: '150px',
                  marginTop: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                }}
              >
                {allowedClients.map((item, index) => {
                  return (
                    <MenuItem onClick={(e) => setClientId(item.id)} key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : null}

            {user && <div className={classes.marginBox}>{user.login}</div>}
            <IconButton
              edge="end"
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenu}
      {desktopMenu}
    </div>
  );
};

export default AppNavBar;
