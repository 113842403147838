import React, { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface StyleProps {
  focused?: boolean;
}

interface PropsType extends StyleProps {
  toolbar?: ReactNode;
  children?: ReactNode;
  className?: string;
  onMouseDown?: Function;
  isFullscreen?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) =>
    createStyles({
      root: {
        border: 'solid 1px',
        marginTop: 20,
        borderColor: 'transparent',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#091122',
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }),
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.13)',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#091122',
          },
        },
      },
      root_focused: {
        borderColor: '#f77874',
        backgroundColor: 'rgba(0, 0, 0, 0.13)',
      },
      root_fullscreen: {
        overflow: 'scroll',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        zIndex: 999,
        padding: 0,
        borderRadius: 0,
        backgroundColor: '#2f294f',
        '&:hover': {
          backgroundColor: '#2f294f',
        },
      },
      grid__root: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      },
      grid__toolbar: {},
      grid__toolbar_fullscreen: {
        position: 'fixed',
        width: '100%',
        backgroundColor: '#091122',
      },
      grid__editor: {
        paddingTop: '42px',
      },
      grid__editor_fullscreen: {
        paddingTop: '52px',
      },
      editor: {
        height: '100%',
        padding: theme.spacing(2),
        wordBreak: 'break-word',
        background: 'transparent',
        '&> [data-slate-editor="true"]': {
          width: '100%',
          height: '100%',
        },
      },
      editor_fullscreen: {
        width: '90%',
        margin: 'auto',
        maxWidth: 800,
        minHeight: 600,
        backgroundColor: '#091122',
      },
      editor_focused: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
      },
    }),
  { name: 'Frame' }
);

const Frame = (props: PropsType, ref) => {
  const { toolbar, focused, children, isFullscreen, className: classNameProp, ...other } = props;

  const classes = useStyles(props);

  const className = clsx(
    classes.root,
    focused && classes.root_focused,
    isFullscreen && classes.root_fullscreen,
    classNameProp
  );

  const editorClassName = clsx(
    classes.editor,
    isFullscreen && classes.editor_fullscreen,
    focused && classes.editor_focused
  );

  return (
    <div {...other} ref={ref} className={className}>
      <div className={classes.grid__root}>
        <div className={clsx(isFullscreen && classes.grid__toolbar_fullscreen)}>
          {toolbar}
          <Divider />
        </div>
        <div className={clsx(isFullscreen && classes.grid__editor_fullscreen)}>
          <Paper className={editorClassName}>{children}</Paper>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Frame);
