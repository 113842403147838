import React, { useCallback } from 'react'
import noop from 'lodash-es/noop'
import { useSlate } from 'slate-react'

import isControlActive from '../../helpers/isControlActive'
import isControlDisabled from '../../helpers/isControlDisabled'

import Toolbar from './Toolbar'

const EditorToolbar = props => {
  const {
    isFullscreen,
    onToggleFullscreen = noop,
    ...other
  } = props;

  const editor = useSlate();

  const onTriggerControl = useCallback((_event, { value, options }) => {
    if (!editor) {
      return;
    }

    if (value === 'fullscreen') {
      return onToggleFullscreen();
    }

    if (value === 'entity') {
      return editor.exec({ type: 'insert_entity', options });
    }

    if (value === 'link') {
      return editor.exec({ type: 'insert_link', options });
    }

    return editor.exec({ type: 'toggle_format', format: value });
  }, [editor, onToggleFullscreen])

  const getIsControlActive = useCallback(type => {
    if (!editor) {
      return;
    }

    if (type === 'fullscreen') {
      return isFullscreen;
    }

    return isControlActive(editor, type);
  }, [editor, isFullscreen]);

  const getIsControlDisabled = useCallback(type => {
    if (!editor) {
      return;
    }

    return isControlDisabled(editor, type);
  }, [editor])

  return (
    <Toolbar
      {...other}
      onTriggerControl={onTriggerControl}
      getIsControlActive={getIsControlActive}
      getIsControlDisabled={getIsControlDisabled}
    />
  )
}

export default EditorToolbar
