import React, { useMemo, useCallback, useContext, forwardRef } from 'react'
import idx from 'idx'
import noop from 'lodash-es/noop'
import merge from 'lodash-es/merge'
import Paper from '@material-ui/core/Paper'
import { Form } from 'react-final-form';
import { useEditor, useSelected, useFocused } from 'slate-react';

import EditorContext from '../../contexts/EditorContext'
import getBlockName from '../../helpers/getBlockName'

import ContainerPaper from './ContainerPaper'
import FormFieldOnChange from './FormFieldOnChange'

type EntityProps = {
  node: any;
  blocks: any;
  editor: any;
  schema: any;
  element: any;
  isFocused: boolean;
  attributes: any;
  isSelected: boolean;
};

const Entity: React.FC<EntityProps> = (props, ref) => {
  const {
    blocks,
    element,
    children,
    attributes,
    ...other
  } = props

  const { data: properties } = element
  const type = idx(properties, _ => _.type)
  const initialValues = useMemo(() => {
    return idx(properties, _ => _.data) || {}
  }, [properties]);

  const editor = useEditor();
  const focused = useFocused();
  const selected = useSelected();
  const description = blocks.find(b => b.type === type);
  const { isFocused } = useContext(EditorContext)

  const onComponentClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onFieldChange = useCallback((name, value) => {
    const payload = merge({}, element, { data: { data: { [name]: value } } })
    editor.exec({ type: 'update_entity', args: { element, payload } })
  }, [editor, element]);

  const onBlockDelete = useCallback(() => {
    setTimeout(() => editor.exec({ type: 'delete_entity' }), 10);
  }, [editor]);


  const onBlockMoveUp = useCallback(() => {
    editor.exec({ type: 'move_entity_up', args: { element } });
  }, [editor, element]);

  const onBlockMoveDown = useCallback(() => {
    editor.exec({ type: 'move_entity_down', args: { element } });
  }, [editor, element]);

  if (typeof description === 'undefined') {
    return <Paper square>Unknown type '{type}'</Paper>;
  }

  if (typeof description.fields === 'undefined') {
    return <Paper square>No fields defined for '{type}'</Paper>;
  }

  return (
    <div {...attributes}>
      {children}
      <div data-slate-editor="true" contentEditable={false}>
        <ContainerPaper
          ref={ref}
          label={getBlockName(description)}
          focused={isFocused}
          onDelete={onBlockDelete}
          onMoveUp={onBlockMoveUp}
          onMoveDown={onBlockMoveDown}
          {...other}
          style={{ border: focused && selected ? 'solid 1px #a7a7a7' : 'solid 1px transparent' }}
        >
          <Form onSubmit={noop} initialValues={initialValues}>
            {() => description.fields.map((field, index) => (
              <FormFieldOnChange
                key={field.name || index}
                {...field}
                onClick={onComponentClick}
                onChange={onFieldChange}
                component={field.type}
              />
            ))}
          </Form>
        </ContainerPaper>
      </div>
    </div>
  );
};

export default forwardRef(Entity)
