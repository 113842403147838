import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Debug from 'debug';
import { withHistory } from 'slate-history';
import { Editor, createEditor, Node } from 'slate'
import { Slate, ReactEditor, Editable, withReact } from 'slate-react';

import dangerouslyExposeInDevelopment from '@/helpers/dangerouslyExposeInDevelopment';

import Leaf from './components/Leaf';
import Frame from './components/Frame';
import Toolbar from './components/Toolbar';
import Element from './components/Element';
import withRichText from './extensions/with-rich-text'

import { handleKeyboardEvent } from './keyboard';

type SlatePropsType = Parameters<typeof Slate>[0]

interface RichTextEditorPropsType {
  blocks: any;
  disabled: boolean;
  value?: SlatePropsType['defaultValue'];
  onChange?: (value: Node[]) => void;
  locale: string;
}

const DEFAULT_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  }
]

const RichTextEditor = (props: RichTextEditorPropsType) => {
  const {
    blocks,
    value: valueProp,
    onChange: onChangeProp,
    locale
  } = props

  const valuePropsState = [valueProp || DEFAULT_VALUE, onChangeProp]
  const valueLocalState = useState(valueProp || DEFAULT_VALUE);
  const isPropsState = typeof onChangeProp === 'function'
  const valueState = isPropsState ? valuePropsState : valueLocalState;

  const [value, setValue] = valueState;
  const [isFullscreen, setIsFullscreen] = useState(false);

  const editor = useMemo(() => withRichText(withHistory(withReact(createEditor()))), []);
  const onKeyDown = useCallback(event => handleKeyboardEvent(event, editor), [editor]);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const renderElement = useCallback(props => <Element {...props} locale={locale} blocks={blocks} />, []);

  useEffect(() => {
    if (isPropsState && !valueProp) {
      setValue(value);
    }
  }, [isPropsState, valueProp, setValue, value])

  const onToggleFullscreen = useCallback(() => {
    setIsFullscreen(!isFullscreen)
  }, [isFullscreen])

  dangerouslyExposeInDevelopment({ ReactEditor, Editor, editor });

  const toolbar = (
    <Toolbar
      blocks={blocks}
      editor={editor}
      isFullscreen={isFullscreen}
      onToggleFullscreen={onToggleFullscreen}
    />
  )

  return (
    <Slate
      value={value}
      editor={editor}
      onChange={value => setValue(value)}
    >
      <Frame
        toolbar={toolbar}
        isFullscreen={isFullscreen}
      >
        <Editable
          onKeyDown={onKeyDown}
          renderLeaf={renderLeaf}
          renderElement={renderElement}
        />
      </Frame>
    </Slate>
  );
};

export default RichTextEditor;
