import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles, createStyles } from '@material-ui/styles';

import { Theme } from '@material-ui/core';

interface PropsType {
  sidebar?: ReactNode;
  children?: ReactNode;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        boxSizing: 'border-box',
      },
      root_sidebar: {
        paddingRight: 300,
        display: 'flex',
      },
      content: {
        boxSizing: 'border-box',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      sidebar: {
        position: 'fixed',
        right: 0,
        height: '100%',
        width: 300,
        overflow: 'auto',
      },
    }),
  { name: 'PageLayout' }
);

const PageLayout = (props: PropsType) => {
  const { children, sidebar } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, sidebar && classes.root_sidebar)}>
      <Container className={classes.content}>
        {children}
      </Container>
      {sidebar && <div className={classes.sidebar}>{sidebar}</div>}
    </div>
  );
};

export default PageLayout;
