import React, { useCallback, useState } from 'react';
import noop from 'lodash/noop';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ReferenceSearch from '@/containers/ReferenceSearch';
import { ContentTypes } from '@/screens/ContentView/schema/types';

export default function LinkDialog(props) {
  const { onClose, onSubmit: onSubmitProp = noop, value, locale, ...other } = props;

  const [url, setUrl] = useState(value.url);
  const [rel, setRel] = useState(value.rel);
  const [reference, setReference] = useState(value.reference);

  const onSubmit = useCallback(
    (event) => {
      onSubmitProp(event, { reference, url, rel });
    },
    [onSubmitProp, url, reference, rel]
  );

  const [type, setType] = React.useState(value.url ? 'url' : 'reference');

  const onChangeUrl = useCallback(
    (event) => {
      setReference(null);
      setUrl(event.target.value);
    },
    [setUrl, setReference]
  );

  const onChangeRel = useCallback(
    (event) => {
      setRel(event.target.value);
    },
    [setRel]
  );

  const onChangeReference = useCallback(
    (newRef) => {
      setUrl(null);
      setReference(newRef);
    },
    [setUrl, setReference]
  );

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const getRepository = () => {
    return typeof window !== 'undefined' ? window.location.href.split('/')[5] : '*';
  };

  return (
    <Dialog
      {...other}
      onClose={onClose}
      PaperProps={{ style: { minWidth: 300 } }}
      aria-labelledby="link-dialog-title"
    >
      <DialogTitle id="link-dialog-title">Edit Link</DialogTitle>
      <DialogContent>
        <RadioGroup aria-label="type" name="type" value={type} onChange={handleChangeType}>
          <FormControlLabel value="reference" control={<Radio />} label="Reference" />
          {type === 'reference' && (
            <ReferenceSearch
              value={reference}
              onChange={onChangeReference}
              placeholder="Search a reference"
              repositories={[getRepository(), 'global']}
              contentTypes={[ContentTypes.PAGE, ContentTypes.GAME]}
              locale={locale}
            />
          )}

          <FormControlLabel value="url" control={<Radio />} label="URL" />
          {type === 'url' && (
            <>
              <TextField
                autoFocus
                fullWidth
                id="url"
                type="url"
                label="Link url"
                value={url || ''}
                margin="dense"
                onChange={onChangeUrl}
              />
              <TextField
                autoFocus
                fullWidth
                id="rel"
                type="string"
                label="Link rel"
                value={rel || ''}
                margin="dense"
                onChange={onChangeRel}
              />
            </>
          )}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
