import {
  createPage,
  createFooter,
  createTranslation,
  createMenu,
  createSeoBox,
  createImage,
  createCardList,
  createCard,
  createTextSection,
  createImageSection,
  createExternalLink,
  createPaymentMethods,
} from './builders';
import { Repository } from '../types';

export const locales = ['en', 'en_CA', 'fr_CA', 'fi', 'de'];

export const page = createPage({ locales });
export const seoBox = createSeoBox({ locales });
export const footer = createFooter({ locales });
export const translation = createTranslation({ locales });
export const menu = createMenu({ locales });
export const image = createImage({ locales });
export const cardList = createCardList({ locales });
export const card = createCard({ locales });
export const textSection = createTextSection({ locales });
export const imageSection = createImageSection({ locales });
export const externalLink = createExternalLink({ locales });
export const paymentMethods = createPaymentMethods({ locales });

const mountgoldIo: Repository = {
  repository: 'mountgoldIo',
  types: [
    footer,
    menu,
    page,
    seoBox,
    translation,
    image,
    cardList,
    card,
    textSection,
    imageSection,
    externalLink,
    paymentMethods,
  ],
};

export default mountgoldIo;
