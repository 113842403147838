import lowerCase from 'lodash-es/lowerCase'
import upperFirst from 'lodash-es/upperFirst'

export default function getBlockName (block) {
  if (!block) {
    return '';
  }

  if (block.title) {
    return block.title
  }

  if (block.type) {
    return upperFirst(lowerCase(block.type))
  }

  return ''
}
