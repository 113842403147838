import React from 'react';
import { FieldComponent } from '../ContentEditor';
import TextFieldFormField from '@/form-fields/TextField';
import { Field } from 'react-final-form';

const TextField: FieldComponent = props => {
  const { title, name, onClick, disabled } = props

  return (
    <Field
      fullWidth
      name={name}
      label={title}
      variant="filled"
      onClick={onClick}
      disabled={disabled}
      component={TextFieldFormField}
      InputProps={{ disableUnderline: true }}
      InputLabelProps={{ shrink: true }}
    />
  );
}

export default TextField;
