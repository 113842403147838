import React, { useCallback, MouseEvent } from 'react'
import clsx from 'clsx'
import noop from 'lodash-es/noop';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { makeStyles, createStyles } from '@material-ui/styles'

export interface ToolbarIconButtonProps extends Omit<IconButtonProps, 'value' | 'onChange'> {
  value: unknown;
  active?: boolean;
  onChange: (e: MouseEvent, v: unknown) => void
}

const useStyles = makeStyles(createStyles({
  root: {
    color: 'rgb(204, 204, 204);'
  },
  root_active: {
    color: '#000'
  }
}), { name: 'Toolbar' })

const ToolbarIconButton = (props: ToolbarIconButtonProps) => {
  const {
    value,
    active,
    children,
    onChange = noop,
    ...other
  } = props;

  const classes = useStyles(props);

  const onMouseDown = useCallback((event: MouseEvent) => {
    onChange(event, value)
  }, [value, onChange]);

  return (
    <IconButton
      {...other}
      disableRipple
      disableFocusRipple
      className={clsx(classes.root, active && classes.root_active)}
      onMouseDown={onMouseDown}
    >
      {children}
    </IconButton>
  )
}

export default ToolbarIconButton
