import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';

import Spinner from '@/components/Spinner';

const useStyles = makeStyles(
  createStyles({
    spinner: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'Loading' }
);

const Loading = () => {
  const classes = useStyles()

  return (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  );
};

export default Loading;
