import React, { FC } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import {
  MdChat,
  MdHome,
  MdPoll,
  MdWhatshot,
  MdHeadsetMic,
  MdCreditCard,
  MdPhotoSizeSelectActual,
  MdAdjust,
} from 'react-icons/md';

import { colors } from '@/theme/settings';

import SidebarLink from './SidebarLink';

interface PropsType {
  open: boolean;
  width: number;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: (props: PropsType) => props.width,
        background: colors.darkPanel,
        position: 'absolute',
        boxShadow: '16px 16px 48px 0 rgba(0, 0, 0, 0.48)',
        transition: 'all 0.3s',
        top: 0,
        left: 0,
        height: '100vh',
        overflowX: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.down('sm')]: {
          width: (props: PropsType) => (props.open ? props.width : 0),
        },
      },
    }),
  { name: 'SidebarLink' }
);

const AppSidebar: FC<PropsType> = props => {
  const { open } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div>
        <SidebarLink disabled to="/dashboard" icon={<MdHome />} exact open={open}>
          DASHBOARD
        </SidebarLink>
        <SidebarLink to="/#" icon={<MdHeadsetMic />} open={open}>
          PLAYERS
        </SidebarLink>
        <SidebarLink to="/payments-fraud/transactions" icon={<MdCreditCard />} open={open}>
          PAYMENTS & FRAUD
        </SidebarLink>
        <SidebarLink to="/analytics" icon={<MdPhotoSizeSelectActual />} open={open}>
          ANALYTICS
        </SidebarLink>
        <SidebarLink disabled to="/product-management" icon={<MdPoll />} open={open}>
          PRODUCT MANAGEMENT
        </SidebarLink>
        <SidebarLink to="/cms/content/global" icon={<MdWhatshot />} open={open}>
          CMS
        </SidebarLink>
        <SidebarLink disabled to="/admin" icon={<MdChat />} open={open}>
          ADMIN
        </SidebarLink>
      </div>
    </div>
  );
};

export default AppSidebar;
