import React from 'react';

import Link from './Link'
import Entity from './Entity'

export default function Element(props) {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'paragraph':
      return <p {...attributes}>{children}</p>
    case 'entity':
      return <Entity {...props} />;
    case 'link':
      return <Link {...props}>{children}</Link>
    default:
      return <div {...attributes}>{children}</div>
  }
}
