import { Editor } from "slate";

const isBlockActive = (editor: Editor, format: string) => {
  const match = Editor.nodes(editor, {
    match: n => n.type === format
  }).next().value;

  return !!match;
}

export default isBlockActive;