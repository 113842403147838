import { Editor, Range } from 'slate'

import getControl from './getControl'
import isBlockActive from './isBlockActive';

const isLinkActive = (editor: Editor) => {
  const link = Editor.nodes(editor, { match: n => n.type === 'link' }).next().value
  return !!link;
}

export default function isControlDisabled(editor: Editor, id: string): boolean {
  const control = getControl(id)

  if (!control) {
    return false;
  }

  const { selection } = editor;
  const isCollapsed = selection ? Range.isCollapsed(selection) : true;

  // Only allow links to created if some text is selected
  if (id === 'link') {
    if (isLinkActive(editor)) {
      return false;
    }

    return isCollapsed;
  }

  if (isBlockActive(editor, 'entity')) {
    return true;
  }

  return false
}
