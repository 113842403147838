import {
  createPage,
  createFooter,
  createTranslation,
  createMenu,
  createSeoBox,
  createImage,
  createCardList,
  createCard,
  createTextSection,
} from './builders';

export const locales = ['en'];

export const page = createPage({ locales });
export const seoBox = createSeoBox({ locales });
export const footer = createFooter({ locales });
export const translation = createTranslation({ locales });
export const menu = createMenu({ locales });
export const image = createImage({ locales });
export const cardList = createCardList({ locales });
export const card = createCard({ locales });
export const textSection = createTextSection({ locales });

const infiniza = {
  repository: 'infiniza',
  types: [footer, menu, page, seoBox, translation, image, cardList, card, textSection],
};

export default infiniza;
