import React, { FC, useContext } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles, createStyles } from '@material-ui/styles';

import Spinner from '@/components/Spinner';
import AppContext from '@/contexts/AppContext';

const useStyles = makeStyles(
  createStyles({
    spinnerContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'AppLoadingModal' }
);

const AppLoadingModal: FC = props => {
  const classes = useStyles(props);
  const appState = useContext(AppContext);

  const { loading } = appState;

  return (
    <Modal open={loading}>
      <div className={classes.spinnerContainer}>
        <Spinner />
      </div>
    </Modal>
  );
};

export default AppLoadingModal;
