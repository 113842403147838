import React from 'react';
import { FieldComponentProps } from '../ContentEditor';
import Image from 'imagekit-react';

const ImageField: React.FC<FieldComponentProps & { endpoint: string }> = ({ title, value, endpoint }) => (
  <div>
    <div>
      <div>{title}</div>
      {!value || value.length == 0 ? (
        'No image'
      ) : (
          <Image
            transformations={[{ w: 120, q: 80 }]}
            path={value}
            urlEndpoint={endpoint}
          />
        )}
    </div>
  </div>
);

export default ImageField;
