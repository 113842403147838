import React from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControlProps } from '@material-ui/core/FormControl';
import { FieldRenderProps } from 'react-final-form';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

// see https://github.com/final-form/react-final-form/issues/175
interface SelectFormFieldProps extends FieldRenderProps<string, HTMLElement> {
  label?: string;
  props?: SelectProps;
  formControlProps?: FormControlProps;
}

const SelectFormField = (props: SelectFormFieldProps) => {
  const { meta, input, label, formControlProps, props: propsProp = {}, ...other } = props;

  const { name, value, onChange, ...inputProps } = input;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <Select
        {...propsProp}
        {...other}
        name={name}
        value={value}
        onChange={onChange}
        inputProps={inputProps as InputBaseComponentProps}
      />

      {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFormField;
