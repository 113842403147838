import React from 'react'

import schema from '@/screens/ContentView/schema';

class ErrorBoundary extends React.Component<{ node: any }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      console.log("node=", this.props.node);
      return <p>Something went wrong.</p>;
    }

    return this.props.children;
  }
}

function contentPreview(_schema: any, repository: string, contentType: string) {
  const repo = schema.find(r => r.repository === repository);
  const type = repo && repo.types.find(t => t.contentType === contentType);
  return type && type.preview;
}

export const ReferencePreview: React.FC<{ node: NodeLike; schema: any, locale?: string | undefined }> = ({ node, schema, locale }) => {
  const Preview = contentPreview(schema, node.repository, node.contentType);

  return <ErrorBoundary node={node}>{Preview ? <Preview {...node} locale={locale} /> : node.contentId}</ErrorBoundary>;
};

export default ReferencePreview
