import React from 'react';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import isUrl from 'is-url';
import LinkDialog from './LinkDialog';
import { Transforms } from 'slate';
import { useEditor, ReactEditor } from 'slate-react';

type Props = {
  attributes: any;
  element: any;
  children: React.ReactNode;
  locale?: string;
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    link: ({ hasLink }: any) => ({
      textDecoration: 'underline',
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0 2px',
      background: hasLink ? 'darkgray' : 'darkred',
    }),
    icon: {
      marginLeft: 2,
      height: 16,
      width: 16,
      cursor: 'pointer',
    },
  })
);

const Link = ({ children, ...props }: Props) => {
  const hasLink = isUrl(props.element.url) || props.element.reference;
  const classes = useStyles({ hasLink, ...props });
  const editor = useEditor();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (_event, { url, reference, rel }) => {
    setOpen(false);

    const path = ReactEditor.findPath(editor, props.element);
    Transforms.setNodes(
      editor,
      { url, reference, rel },
      {
        at: path,
      }
    );
  };

  return (
    <span {...props.attributes} className={classes.link}>
      {children}
      {hasLink ? (
        <LinkIcon className={classes.icon} onClick={handleOpen} />
      ) : (
        <LinkOffIcon className={classes.icon} onClick={handleOpen} />
      )}
      <LinkDialog
        locale={props.locale}
        value={props.element}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </span>
  );
};

export default Link;
