import React from 'react';
import { Field } from 'react-final-form';

import ReferenceSearch from '@/containers/ReferenceSearch';

const ReferenceField: FieldComponent = props => {
  const { name, title, ...other } = props

  return (
    <Field name={name}>
      {({ input }) => (
        <>
          {title}
          <ReferenceSearch
            {...other}
            value={input.value}
            onChange={input.onChange}
            placeholder='Search a reference'
          />
        </>
      )}
    </Field>
  );
}

export default ReferenceField;
