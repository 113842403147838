import React, { useState, useCallback } from 'react';
import { FieldComponentProps, Field } from '../ContentEditor';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useField, Field as FinalFormField } from 'react-final-form';
import CheckboxFormField from '@/form-fields/Checkbox';

const LOCALE_TO_NAME: { [key: string]: string } = {
  en: 'English',
  jp: 'Japanese',
  en_CA: 'Canada (English)',
  fr_CA: 'Canada (French)',
  fi: 'Finnish',
  mt: 'Maltese',
  no: 'Norwegian',
  de: 'German',
  de_AT: 'German (AT)',
};

type TabPanelProps = {
  value: string;
  selectedValue: string;
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, selectedValue, ...other } = props;

  return (
    <Typography
      id={`simple-tabpanel-${value}`}
      role="tabpanel"
      hidden={value !== selectedValue}
      component="div"
      {...other}
    >
      {value === selectedValue && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const FieldComponent = (props) => {
  const { field, locale, locales, schema } = props;
  const name = `${field.name}.${locale}`;
  const enabledName = `${field.name}$enabled.${locale}`;
  const { input } = useField(enabledName);

  const Component = field.type;

  const disabled = locale !== locales[0] && !input.value;

  return (
    <Grid container justify="space-between" alignItems="flex-start" key={field.name} spacing={2}>
      {locale !== locales[0] && (
        <Grid item>
          <FinalFormField
            name={enabledName}
            type="checkbox"
            component={CheckboxFormField}
            style={{ marginTop: 10 }}
          />
        </Grid>
      )}
      <Grid item style={{ width: '100%', flex: 1 }} xs={12}>
        <Component
          key={field.name}
          {...field}
          name={name}
          locale={locale}
          title={`${field.title} (${locale})`}
          schema={schema}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

function translatable(fields: Field[], locales: string[]) {
  return function Translateable(_props: FieldComponentProps) {
    const [tabsValue, setTabsValue] = useState(locales[0]);

    const onChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
      setTabsValue(newValue);
    };

    //  pt={3} pr={5} pb={3} pl={5}
    return (
      <Paper>
        <Box>
          <Tabs value={tabsValue} onChange={onChange}>
            {locales.map((locale) => (
              <Tab label={LOCALE_TO_NAME[locale] || locale} key={locale} value={locale} />
            ))}
          </Tabs>
          {locales.map((locale) => (
            <TabPanel value={locale} key={locale} selectedValue={tabsValue}>
              {fields.map((field) => (
                <FieldComponent key={field.name} field={field} locale={locale} locales={locales} />
              ))}
            </TabPanel>
          ))}
        </Box>
      </Paper>
    );
  };
}

export default translatable;
