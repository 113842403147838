import React, { useRef, useCallback } from 'react';
import { FieldComponentProps } from '../ContentEditor';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useApolloClient } from '@apollo/react-hooks';
import idx from 'idx';
import { Field } from 'react-final-form';
import {
  SearchContentReference as SearchContentReferenceType,
  SearchContentReferenceVariables,
} from '@/types/generated/SearchContentReference';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import ReferencePreview, { SearchContentReference } from '@/containers/ReferenceSearch';

import '@webscopeio/react-textarea-autocomplete/style.css';

const useStyles = makeStyles(
  createStyles({
    textArea: {
      width: '100%',
      minHeight: '100px',
    },
  }),
  { name: 'ReferenceMany' }
);

type NodeLike = {
  contentId: string;
  contentType: string;
  repository: string;
  draft: any;
};

const Loading = () => <div>Loading</div>;

const ReferenceMany: React.FC<FieldComponentProps & { contentTypes?: string[] }> = (props) => {
  const classes = useStyles(props);
  const ref = useRef();
  const apollo = useApolloClient();

  const onChange = useCallback((e) => {
    props.onChange(e?.target?.value.split('\n').map((s: any) => s.trim()));
  }, []);

  return (
    <ReactTextareaAutocomplete
      ref={ref}
      loadingComponent={Loading}
      className={classes.textArea}
      disabled={props.disabled}
      onChange={onChange}
      containerStyle={{ color: 'black' }}
      value={(typeof props.value === 'string'
        ? props.value.split('\n').map((s: any) => s.trim())
        : props.value || []
      )?.join('\n')}
      trigger={{
        ':': {
          dataProvider: async (token: string) => {
            const resp = await apollo.query<
              SearchContentReferenceType,
              SearchContentReferenceVariables
            >({
              query: SearchContentReference,
              variables: {
                query: token,
                contentTypes: props.contentTypes,
              },
            });
            const nodes = (idx(resp, (_) => _.data.viewer.content.edges) || []).map(
              (edge) => edge.node
            );
            return nodes;
          },
          component: ({ entity }: { entity: NodeLike }) => <ReferencePreview node={entity} />,
          output: (item: NodeLike) => item.contentId,
        },
      }}
    />
  );
};

const ReferenceManyField: FieldComponent = (props) => {
  const { name, title, ...other } = props;

  return (
    <Field name={name}>
      {({ input }) => <ReferenceMany {...other} value={input.value} onChange={input.onChange} />}
    </Field>
  );
};

export default ReferenceManyField;
