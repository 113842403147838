import React from 'react';
import translatable from './translatable';
import TextField from './TextField';
import BooleanField from './BooleanField';
import ImageField from './ImageField';
import ReferenceField from './ReferenceField';
import ReferenceManyField from './ReferenceManyField';
import BlockEditorField from './BlockEditorField';
import { Block, FieldComponent } from '../ContentEditor';
import DateTimeField from './DateTimeField';
import NumberField from './NumberField';
import SelectField from './SelectField';

function text(): FieldComponent {
  return TextField;
}

function bool(): FieldComponent {
  return BooleanField;
}

function dateTime(): FieldComponent {
  return DateTimeField;
}

function select({ types }: { types: any }): FieldComponent {
  return props => <SelectField types={types} {...props} />;
}

function number(): FieldComponent {
  return NumberField;
}

function image({ endpoint }: { endpoint: string }): FieldComponent {
  return props => <ImageField endpoint={endpoint} {...props} />;
}

function reference({ contentTypes, repositories }: { contentTypes?: string[], repositories?: string[] } = {}): FieldComponent {
  return props => <ReferenceField contentTypes={contentTypes} repositories={repositories} {...props} />;
}

function referenceMany({ contentTypes }: { contentTypes?: string[] } = {}): FieldComponent {
  return props => <ReferenceManyField contentTypes={contentTypes} {...props} />;
}

function blockEditor({ blocks }: { blocks: Block[] } = { blocks: [] }): FieldComponent {
  return props => <BlockEditorField blocks={blocks} {...props} />
}

export {
  translatable,
  text,
  bool,
  image,
  reference,
  blockEditor,
  dateTime,
  number,
  referenceMany,
  select,
};
