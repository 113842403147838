import React, { Fragment, createElement, useCallback } from 'react'
import noop from 'lodash-es/noop'
import { OnChange } from 'react-final-form-listeners';

const FormFieldOnChange = props => {
  const {
    name,
    component,
    onChange: onChangeProp = noop,
    ...other
  } = props

  const onChange = useCallback(value => {
    onChangeProp(name, value)
  }, [name, onChangeProp])

  return (
    <Fragment>
      {createElement(component, { ...other, name })}
      <OnChange name={name}>
        {onChange}
      </OnChange>
    </Fragment>
  )
}

export default FormFieldOnChange
