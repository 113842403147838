import { Editor } from 'slate'
import { isKeyHotkey } from 'is-hotkey';
import isBlockActive from '../helpers/isBlockActive';

export function handleKeyboardEvent(event: KeyboardEvent, editor: Editor) {
  if (isKeyHotkey('mod+b', event)) {
    return editor.exec({ type: 'toggle_format', format: 'bold' });
  }

  if (isKeyHotkey('mod+i', event)) {
    return editor.exec({ type: 'toggle_format', format: 'italic' });
  }

  if (isKeyHotkey('mod+u', event)) {
    return editor.exec({ type: 'toggle_format', format: 'underlined' });
  }

  if (
    isKeyHotkey('enter', event) &&
    isBlockActive(editor, 'entity') &&
    editor.selection
  ) {
    return editor.exec({ type: 'insert_entity_break' });
  }
}
