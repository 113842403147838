import React, { FC } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { FieldRenderProps } from 'react-final-form';

const CheckboxFormField: FC<FieldRenderProps<string, HTMLInputElement>> = props => {
  const { input, meta, ...other } = props;
  const { checked, value, name, onChange, ...otherInput } = input;

  return (
    <Checkbox
      {...other}
      name={name}
      checked={checked}
      onChange={onChange}
      inputProps={otherInput}
    />
  );
};

export default CheckboxFormField;
