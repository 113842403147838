import React from 'react';
import { translatable, text, bool, image, blockEditor, dateTime, reference } from '../fields';
import Image from 'imagekit-react';
import uniq from 'lodash-es/uniq';

import * as mountgold from './mountgold';
import * as refuelcasino from './refuelcasino';
import * as amokcasino from './amokcasino';
import * as raptorcasino from './raptorcasino';
import { ContentTypes } from './types';
import { createTranslation } from './builders';
import { Repository } from '../types';

const thumbnailEndpoint = 'https://ik.imagekit.io/tz66n6vb9';

const globalLocales = uniq([
  ...refuelcasino.locales,
  ...mountgold.locales,
  ...amokcasino.locales,
  ...raptorcasino.locales,
]);

export const tag = {
  contentType: ContentTypes.TAG,
  preview: ({ draft }: any) => draft.name.en,
  fields: [
    {
      name: 'tagId',
      title: 'Tag ID/Slug',
      type: text(),
    },
    {
      type: translatable(
        [
          {
            name: 'name',
            title: 'Name',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
  ],
};

export const license = {
  contentType: ContentTypes.LICENSE,
  preview: ({ draft }: any) => draft.name,
  fields: [
    {
      name: 'licenseId',
      title: 'License ID',
      type: text(),
    },
    {
      name: 'name',
      title: 'Name',
      type: text(),
    },
  ],
};

export const gameType = {
  contentType: ContentTypes.GAME_TYPE,
  preview: ({ draft }: any) => draft.title.en,
  fields: [
    {
      type: translatable(
        [
          {
            name: 'slug',
            title: 'Slug',
            type: text(),
          },
          {
            name: 'name',
            title: 'Name',
            type: text(),
          },
          {
            name: 'titleTemplate',
            title: 'Title Template',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
  ],
};

export const game = {
  contentType: ContentTypes.GAME,
  readonly: true,
  preview: function GamePreview({ draft }: any) {
    return (
      <div>
        {draft?.thumbnail && (
          <Image
            path={draft.thumbnail}
            transformations={[{ w: 60 }]}
            urlEndpoint={thumbnailEndpoint}
            style={{ width: 25, height: 25 }}
          />
        )}{' '}
        {draft?.name?.en}
      </div>
    );
  },
  fields: [
    {
      name: 'gameId',
      title: 'Game ID/Slug',
      type: text(),
    },
    {
      type: translatable(
        [
          {
            name: 'name',
            title: 'Name',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
    {
      name: 'rtp',
      title: 'RTP',
      type: text(),
    },
    {
      name: 'lines',
      title: 'Lines',
      type: text(),
    },
    {
      name: 'hasFreeRounds',
      title: 'Has Free Rounds?',
      type: bool(),
    },
    {
      name: 'hasFreeSpins',
      title: 'Has Freespins?',
      type: bool(),
    },
    {
      name: 'isBranded',
      title: 'Is Branded?',
      type: bool(),
    },
    {
      name: 'thumbnail',
      title: 'Thumbnail',
      type: image({
        endpoint: thumbnailEndpoint,
      }),
    },
  ],
};

export const gameCategory = {
  contentType: ContentTypes.GAME_CATEGORY,
  preview: ({ draft }: any) => (draft && draft.name && draft.name.en ? draft.name.en : ''),
  title: ({ draft }: any) => draft?.name?.en,
  fields: [
    {
      type: translatable(
        [
          {
            name: 'slug',
            title: 'Slug',
            type: text(),
          },
          {
            name: 'name',
            title: 'Name',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
    {
      name: 'page',
      title: 'Page',
      type: reference({ contentTypes: [ContentTypes.PAGE], repositories: ['mrgold'] }),
    },
  ],
};

export const maintenanceWindow = {
  contentType: ContentTypes.MAINTENANCE_WINDOW,
  preview: ({ draft }: any) => draft.reason,
  fields: [
    {
      name: 'reason',
      title: 'Reason',
      type: text(),
    },
    {
      name: 'from',
      title: 'From',
      type: dateTime(),
    },
    {
      name: 'to',
      title: 'to',
      type: dateTime(),
    },
  ],
};

export const gameProvider = {
  contentType: ContentTypes.GAME_PROVIDER,
  preview: ({ draft }: any) => draft.name.en,
  fields: [
    {
      type: translatable(
        [
          {
            name: 'name',
            title: 'Name',
            type: text(),
          },
          {
            name: 'slug',
            title: 'Slug',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
    {
      name: 'logo',
      title: 'Logo',
      type: text(),
    },
    {
      name: 'enabled',
      title: 'Enabled',
      type: bool(),
    },
    {
      name: 'gameProviderId',
      title: 'Game Provider ID',
      type: text(),
    },
    {
      name: 'integrationId',
      title: 'Integration ID',
      type: text(),
    },

    // TODO: This should be a 'referenceMany'

    // {
    //   name: 'maintenanceWindow',
    //   title: 'Maintenance Window',
    //   type: blockEditor({
    //     blocks: [
    //       {
    //         type: 'referencedMaintenanceWindow',
    //         fields: [
    //           {
    //             name: 'maintenanceWindow',
    //             title: 'Maintenance Windows',
    //             type: reference({ contentTypes: [maintenanceWindow.contentType] }),
    //           },
    //         ],
    //       },
    //     ],
    //   }),
    // },
  ],
};

export const error = {
  contentType: ContentTypes.ERROR,
  preview: ({ draft }: any) => (draft && draft.title && draft.title.en ? draft.title.en : ''),
  fields: [
    {
      name: 'errorType',
      title: 'Error Type',
      type: text(),
    },
    {
      type: translatable(
        [
          {
            name: 'title',
            title: 'Title',
            type: text(),
          },
          {
            name: 'message',
            title: 'Message',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
  ],
};

export const country = {
  contentType: ContentTypes.COUNTRY,
  preview: ({ draft }: any) => draft.name.en,
  fields: [
    {
      name: 'code',
      title: 'Country Code',
      type: text(),
    },
    {
      type: translatable(
        [
          {
            name: 'name',
            title: 'Country Name',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
  ],
};
export const translation = createTranslation({ locales: globalLocales });

export const fundingOption = {
  contentType: ContentTypes.FUNDING_OPTION,
  preview: ({ draft }: any) => draft.title.en,
  fields: [
    {
      type: translatable(
        [
          {
            name: 'title',
            title: 'Title',
            type: text(),
          },
          {
            name: 'description',
            title: 'Description',
            type: text(),
          },
        ],
        globalLocales
      ),
    },
    {
      name: 'key',
      title: 'KEY',
      type: text(),
    },
  ],
};

const global: Repository = {
  repository: 'global',
  types: [
    game,
    tag,
    maintenanceWindow,
    gameProvider,
    gameType,
    license,
    error,
    country,
    translation,
    gameCategory,
    fundingOption,
  ],
};

export default global;
