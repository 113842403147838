import {
  createAccordion,
  createPage,
  createLoginForm,
  createEnterCodeForm,
  createGameList,
  createGameSearchBox,
  createGameSearchTextField,
  createGameDescription,
  createGameCampaign,
  createGameStudio,
  createFooter,
  createCashier,
  createUpdateEmailForm,
  createUpdatePersonalDetailsForm,
  createUpdateAddressForm,
  createUpdateMarketingSettingsForm,
  createLockAccountForm,
  createUpdateDepositLimitForm,
  createUpdateWagerLimitForm,
  createUpdateLossLimitForm,
  createUpdateSessionTimeLimitForm,
  createAccessCashier,
  createMenu,
  createAdditionalInformationForm,
  createSeoBox,
  createCustomContentBox,
  createCookieConsent,
  createImage,
  createPaymentMethods,
  createError404,
  createCurrency,
  createExternalLink,
  createTermsAndConditions,
  createPrivacyPolicy,
  createRepositoryGameCategory,
  createBonusProgram,
  createSteps,
  createPromotion,
  createKycValidationForm,
  createKycVerificationForm,
  createForm,
  createBanner,
  createConfig,
} from './builders';
import { reference, text, translatable } from '../fields';
import { ContentTypes } from './types';
import { ContentDescription, Repository } from '../types';
import idx from 'idx';

export const locales = ['en', 'en_CA', 'fr_CA', 'fi', 'no', 'de_AT'];

export enum RaptorCasinoContentTypes {
  PAYMENT_PROVIDER = 'paymentProvider',
}

export function createPaymentProvider({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: RaptorCasinoContentTypes.PAYMENT_PROVIDER,
    preview: ({ draft }: any) => idx(draft, (_) => _.name.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Provider Name',
              type: text(),
            },
            {
              name: 'logo',
              title: 'Logo',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'processTime',
              title: 'Process Time',
              type: text(),
            },
            {
              name: 'fee',
              title: 'Fee',
              type: text(),
            },
            {
              name: 'minAmount',
              title: 'Min amount',
              type: text(),
            },
            {
              name: 'maxAmount',
              title: 'Max amount',
              type: text(),
            },
            {
              name: 'depositKey',
              title: 'Deposit Transaction Key',
              type: text(),
            },
            {
              name: 'withdrawalKey',
              title: 'Withdrawal Transaction Key',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export const page = createPage({
  locales,
  additionalBlocks: [
    {
      type: 'gameCampaignBlock',
      fields: [
        {
          name: 'gameCampaign',
          title: 'Game Campaign',
          type: reference({ contentTypes: [ContentTypes.GAME_CAMPAIGN] }),
        },
      ],
    },
  ],
});

export const customContentBox = createCustomContentBox({
  locales,
  additionalBlocks: [],
});

export const accordion = createAccordion({ locales });
export const seoBox = createSeoBox({ locales });
export const loginForm = createLoginForm({ locales });
export const enterCodeForm = createEnterCodeForm({ locales });
export const gameList = createGameList({ locales });
export const gameSearchBox = createGameSearchBox({ locales });
export const gameSearchTextField = createGameSearchTextField({ locales });
export const gameDescription = createGameDescription({ locales });
export const gameCampaign = createGameCampaign({ locales });
export const gameStudio = createGameStudio({ locales });
export const footer = createFooter({ locales });
export const cashier = createCashier({ locales });
export const menu = createMenu({ locales });
export const additionalInformationForm = createAdditionalInformationForm({ locales });
export const updateEmailForm = createUpdateEmailForm({ locales });
export const updatePersonalDetailsForm = createUpdatePersonalDetailsForm({ locales });
export const updateAddressForm = createUpdateAddressForm({ locales });
export const updateMarketingSettingsForm = createUpdateMarketingSettingsForm({ locales });
export const lockAccountForm = createLockAccountForm({ locales });
export const updateDepositLimitForm = createUpdateDepositLimitForm({ locales });
export const updateWagerLimitForm = createUpdateWagerLimitForm({ locales });
export const updateLossLimitForm = createUpdateLossLimitForm({ locales });
export const updateSessionTimeLimitForm = createUpdateSessionTimeLimitForm({ locales });
export const accessCashier = createAccessCashier({ locales });
export const cookieConsent = createCookieConsent({ locales });
export const image = createImage({ locales });
export const paymentMethods = createPaymentMethods({ locales });
export const error404 = createError404({ locales });
export const currency = createCurrency();
export const externalLink = createExternalLink({ locales });
export const paymentProvider = createPaymentProvider({ locales });
export const termsAndConditions = createTermsAndConditions({ locales });
export const privacyPolicy = createPrivacyPolicy({ locales });
export const repositoryGameCategory = createRepositoryGameCategory();
export const bonusProgram = createBonusProgram({ locales });
export const steps = createSteps({ locales });
export const promotion = createPromotion({ locales });
export const kycValidation = createKycValidationForm({ locales });
export const kycVerification = createKycVerificationForm({ locales });
export const form = createForm({ locales });
export const banner = createBanner();
export const config = createConfig();

const raptorcasino: Repository = {
  repository: 'raptorcasino',
  types: [
    accessCashier,
    accordion,
    additionalInformationForm,
    banner,
    bonusProgram,
    cashier,
    cookieConsent,
    config,
    currency,
    customContentBox,
    enterCodeForm,
    error404,
    externalLink,
    footer,
    form,
    gameList,
    gameSearchBox,
    gameSearchTextField,
    gameCampaign,
    gameDescription,
    gameStudio,
    image,
    kycValidation,
    kycVerification,
    lockAccountForm,
    loginForm,
    menu,
    page,
    paymentMethods,
    paymentProvider,
    privacyPolicy,
    promotion,
    repositoryGameCategory,
    seoBox,
    steps,
    termsAndConditions,
    updateAddressForm,
    updateDepositLimitForm,
    updateEmailForm,
    updateLossLimitForm,
    updateMarketingSettingsForm,
    updatePersonalDetailsForm,
    updateSessionTimeLimitForm,
    updateWagerLimitForm,
  ],
};

export default raptorcasino;
