import { FC, ComponentType, useContext, useEffect, createElement } from 'react';
import SessionContext, { SessionStatus } from '@/contexts/SessionContext';

import { redirectTo } from '@/helpers/redirect'
import Loading from './Loading';

const PrivateRoute: FC<{ component: ComponentType }> = props => {
  const { component, ...other } = props;
  const session = useContext(SessionContext);

  useEffect(() => {
    if (session.status === SessionStatus.LOGGED_OUT) {
      redirectTo('/login')
    }
  }, [session, session.status]);

  if (session.status === SessionStatus.LOGGED_IN) {
    return createElement(component, other);
  }

  return createElement(Loading)
};

export default PrivateRoute;
