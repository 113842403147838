import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const IDENTITY = gql`
  query Identity {
    viewer {
      identity {
        orgs
        brands
      }
    }
  }
`;

export function useIdentity() {
  const { loading, data } = useQuery<any>(IDENTITY);

  return {
    brands: data?.viewer?.identity?.brands,
    orgs: data?.viewer?.identity?.orgs,
    loading,
  };
}
