import React from 'react';
import { FieldComponent } from '../ContentEditor';
import SelectFormField from '@/form-fields/Select';
import { Field } from 'react-final-form';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';

const SelectField: FieldComponent = ({ value, title, name, types, onClick }: any) => {
  return (
    <Field
      fullWidth
      disableUnderline
      name={name}
      props={{ input: <FilledInput /> }}
      onClick={onClick}
      label={title}
      component={SelectFormField}
      required
      value={value || ''}
    >
      {types.map((item: any) => (
        <MenuItem key={item.value} value={item.value}>
          {item.text}
        </MenuItem>
      ))}
    </Field>
  );
};

export default SelectField;
