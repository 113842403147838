import { Editor, Transforms, Range } from 'slate'
import { ReactEditor } from 'slate-react'
import Debug from 'debug'

import { TEXT_FORMATS, LIST_FORMATS, BLOCK_FORMATS } from '../../constants'
import isMarkActive from '../../helpers/isMarkActive'
import isBlockActive from '../../helpers/isBlockActive'

const debug = Debug('dino:RTE:with-rich-text:command')

export function select_entity(editor: ReactEditor, command: any) {
  const { args } = command
  const { element } = args
  const path = ReactEditor.findPath(editor, element);

  Transforms.select(editor, path);
}

export function toggle_format_entity(_editor: ReactEditor, _command: any) {
  return;
}

export function toggle_format_text(editor: ReactEditor, command: any) {
  const { format } = command;
  const isActive = isMarkActive(editor, format);

  debug('toggle_format_text: %s, active: %s', format, isActive);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export function toggle_format_block(editor: ReactEditor, command: any) {
  const { format } = command;
  const isActive = isBlockActive(editor, format);
  const isList = LIST_FORMATS.includes(format);

  debug('toggle_format_block %s, active: %s', format, isActive);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_FORMATS.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    Transforms.wrapNodes(editor, { type: format, children: [] })
  }
}

export function toggle_format(editor: ReactEditor, command: any) {
  const { format } = command

  if (format === 'entity') {
    return toggle_format_entity(editor, command);
  }

  if (TEXT_FORMATS.includes(format)) {
    return toggle_format_text(editor, command);
  }

  if (BLOCK_FORMATS.includes(format)) {
    return toggle_format_block(editor, command);
  }
}

export function insert_entity(editor: ReactEditor, command: any) {
  const { options } = command
  const { type } = options

  debug('insert_entity %s', type)

  Transforms.insertNodes(editor, [{
    data: options,
    type: 'entity',
    children: [{ text: '' }],
  }], { hanging: true });
}

export function insert_entity_break(editor: ReactEditor, _command: any) {
  const fp = editor.selection.focus.path
  const pp = fp[0] + 1
  const at = [pp]

  Transforms.insertNodes(editor, [{
    type: 'paragraph',
    children: [{ text: '' }],
  }], { at })

  Transforms.select(editor, [pp])
}

export function delete_entity(editor: ReactEditor, _command: any) {
  Transforms.removeNodes(editor, { match: n => n.type === 'entity' })
}

export function update_entity(editor: ReactEditor, command: any) {
  const { args } = command
  const { element, payload } = args
  const path = ReactEditor.findPath(editor, element);
  Transforms.setNodes(editor, payload, { at: path })
}

export function move_entity_up(editor: ReactEditor, command: any) {
  const el = command.args.element
  const at = ReactEditor.findPath(editor, el)
  const pp = Math.max(0, at[0] - 1)
  const to = [pp]

  Transforms.moveNodes(editor, { at, to })
  Transforms.select(editor, to)
}

export function move_entity_down(editor: ReactEditor, command: any) {
  const el = command.args.element
  const at = ReactEditor.findPath(editor, el)
  const pp = at[0] + 1
  const to = [pp]

  Transforms.moveNodes(editor, { at, to })
  Transforms.select(editor, to);
}

const isLinkActive = (editor: ReactEditor) => {
  const link = Editor.nodes(editor, { match: n => n.type === 'link' }).next().value
  return !!link;
}

const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

export function insert_link(editor: ReactEditor, _command: any) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link = {
    type: 'link',
    children: [],
  };

  if (!isCollapsed) {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}
