import React from 'react'

export default function Leaf (props) {
  const { attributes, children, leaf } = props

  const child = ['bold', 'italic', 'code', 'underlined'].reduce((elem, format) => {
    if (!leaf[format]) {
      return elem;
    }

    let wrap = elem

    if (format === 'bold') {
      wrap = <strong>{elem}</strong>
    }

    if (format === 'code') {
      wrap = <code>{elem}</code>
    }

    if (format === 'italic') {
      wrap = <em>{elem}</em>
    }

    if (format === 'underlined') {
      wrap = <u>{elem}</u>
    }

    return wrap
  }, children);

  return <span {...attributes}>{child}</span>
}
