import React from 'react';
import { FieldComponent } from '../ContentEditor';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxFormField from '@/form-fields/Checkbox';
import { Field } from 'react-final-form';

const BooleanField: FieldComponent = ({ title, name, value }) => (
  <FormControlLabel
    label={title}
    control={
      <Field
        name={name}
        type="checkbox"
        component={CheckboxFormField}
        checked={value}
      />
    }
  />
);

export default BooleanField;
