import React, { useCallback } from 'react'
import noop from 'lodash-es/noop'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import lowerCase from 'lodash-es/lowerCase'
import upperFirst from 'lodash-es/upperFirst'

const InsertBlockSelect = props => {
  const {
    options = [],
    onChange: onChangeProp = noop
  } = props

  const onChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeProp(event, event.target.value)
  }, [onChangeProp])

  return (
    <Select
      value='default'
      variant='outlined'
      style={{ minWidth: '150px', marginTop: 0 }}
      onChange={onChange}
    >
      <MenuItem disabled value="default">
        Insert block
      </MenuItem>
      {Object.values(options).map((value: any, index: number) => (
        <MenuItem key={value.type + index} value={value.type}>
          {upperFirst(lowerCase(value.type))}
        </MenuItem>
      ))}
    </Select>
  )
}

export default InsertBlockSelect
