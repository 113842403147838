import React, { useEffect, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import idx from 'idx';
import map from 'lodash-es/map';
import get from 'lodash-es/get';
import omit from 'lodash-es/omit';
import noop from 'lodash-es/noop';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Theme } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles } from '@material-ui/styles';

import SearchField from '@/components/SearchField';
import formatCountryCode from '@/helpers/formatCountryCode';
import clsx from 'clsx';

import { SearchPlayer, SearchPlayerVariables } from '@/types/generated/SearchPlayer';

interface Suggestion {
  label: string;
  value: string;
  description?: string;
}

interface PropsType {
  onChange: Function;
  className?: string;
}

const SearchPlayerQuery = gql`
  query SearchPlayer($keyword: String) {
    viewer {
      playersAsYouTypeV2(firstName: $keyword, lastName: $keyword, playerId: $keyword) {
        edges {
          node {
            brand {
              name
            }
            playerId
            lastName
            firstName
            countryCode
            brand {
              name
            }
            player {
              email
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
      minWidth: 300
    }
  },
  listItemText: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  option: {
    padding: 0
  },
  autocomplete__inputRoot: {
    flexWrap: 'nowrap'
  },
  autocomplete__clearIndicator: {
    color: '#fff'
  },
  autocomplete__popupIndicator: {
    display: 'none'
  },
  autocomplete__option: {
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.selected
    }
  },
  autocomplete__loading: {
    display: 'none'
  }
}), { name: 'AppSearch' });

function convertGQLResponseToOptions(data: SearchPlayer | undefined) {
  const nodes = map(idx(data, _ => _.viewer.playersAsYouTypeV2.edges), edge => {
    return edge.node;
  });

  const options = map(nodes, node => ({
    value: node.playerId,
    label: `${node.firstName} ${node.lastName}`,
    description: [formatCountryCode(node.countryCode), idx(node, _ => _.brand.name), idx(node, _ => _.player.email)]
      .filter(_ => _)
      .join(', '),
  }));

  return options;
}

const Option = props => {
  const { value, label, selected, classes, description } = props;

  return (
    <ListItem
      disableGutters
      key={value}
      style={{ fontWeight: selected ? 500 : 400 }}
      className={classes.option}
      component="div"
    >
      <ListItemText
        title={description}
        primary={label}
        className={classes.listItemText}
        secondary={description}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  )
}

const SearchBox = (props: PropsType) => {
  const { onChange: onChangeProp = noop } = props;
  const classes = useStyles(props);

  const [options, setOptions] = useState<Suggestion[]>([]);
  const [inputValue, setInputValue] = useState();
  const [value, setValue] = useState({ value: '' });

  const onChange = useCallback((_event, option) => {
    if (option) {
      setValue({ value: '' })
      onChangeProp(option.value)
    }
  }, [setValue, onChangeProp])

  const onInputChange = useCallback(event => {
    setInputValue(event.target.value)
  }, [setInputValue]);

  const { data, loading } = useQuery<SearchPlayer, SearchPlayerVariables>(SearchPlayerQuery, {
    skip: !inputValue,
    variables: { keyword: inputValue },
  });

  useEffect(() => {
    if (!loading) {
      setOptions(convertGQLResponseToOptions(data));
    }
  }, [setOptions, loading, data]);

  return (
    <div className={clsx(classes.root, props.className)}>
      <Autocomplete
        // debug
        clearOnEscape
        filterOptions={(options) => options}
        value={value}
        options={options}
        loading={loading}
        onChange={onChange}
        noOptionsText='No results have been found'
        getOptionLabel={option => idx(option, _ => _.label) || ''}
        renderInput={props => (
          <SearchField
            {...props}
            rootRef={props.InputProps.ref}
            loading={loading}
            onChange={onInputChange}
            InputProps={omit(props.InputProps, 'ref')}
          />
        )}
        renderOption={(option, state) => {
          return (
            <Option
              {...option}
              classes={classes}
              selected={state.selected}
            />
          )
        }}
        classes={{
          option: classes.autocomplete__option,
          loading: classes.autocomplete__loading,
          inputRoot: classes.autocomplete__inputRoot,
          clearIndicator: classes.autocomplete__clearIndicator,
          popupIndicator: classes.autocomplete__popupIndicator
        }}
      />
    </div>
  );
};

export default SearchBox;
