import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { transparentize } from 'polished';
import { Link, LinkProps } from '@reach/router';
import { makeStyles, createStyles } from '@material-ui/styles';

import { colors } from '@/theme/settings';

interface PropsType {
  icon: ReactNode;
  open: boolean;
  exact?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    root: {},
    root_disabled: {
      opacity: 0.5,
    },
    container: {
      display: 'grid',
      gridTemplateColumns: '56px 1fr',
      whiteSpace: 'nowrap',
      height: 56,
      background: transparentize(1, colors.darkElement),
      boxShadow: `inset 0px 0px 0px 0px ${colors.active} `,
      transition: 'all 0.2s',
      '.isActive &': {
        background: transparentize(0, colors.darkElement),
        boxShadow: `inset 3px 0px 0px 0px ${colors.active}`,
      },
    },
    icon: {
      display: 'grid',
      margin: 'auto',
    },
    content: {
      display: 'grid',
      margin: 'auto 0',
      fontSize: 12,
      letterSpacing: '1.2px',
      fontWeight: 900,
      opacity: (props: PropsType) => (props.open ? 1 : 0),
      transition: 'opacity 0.3s',
    },
  }),
  { name: 'SidebarLink' }
);

const SidebarLink: FC<LinkProps<any> & PropsType> = props => {
  const { ref, icon, open, exact, children, disabled, className: classNameProp, ...other } = props;

  const classes = useStyles(props);

  const isPartiallyActive = React.useCallback(
    ({ isCurrent, isPartiallyCurrent }: { isCurrent: boolean; isPartiallyCurrent: boolean }) => {
      if (exact) {
        return isCurrent ? { className: 'isActive' } : {};
      }
      return isPartiallyCurrent ? { className: 'isActive' } : {};
    },
    [exact]
  );

  const className = clsx(classNameProp, classes.root, disabled && classes.root_disabled);
  const Component = disabled ? 'div' : Link;
  const compProps = disabled ? { className } : { className, getProps: isPartiallyActive, ...other };

  return (
    <Component {...compProps}>
      <div className={classes.container}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.content}>{children}</div>
      </div>
    </Component>
  );
};

export default SidebarLink;
