import React from 'react'
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import Spinner from '@/components/Spinner';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    margin: 0,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    }
  },
  icon: {
    width: 40,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchfield: {
    flexGrow: 1,
    position: 'relative',
    boxSizing: 'border-box',
    paddingLeft: 32,
    paddingRight: 8,
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    padding: theme.spacing(1.25),
  }
}), { name: 'SearchField' });

const SearchField = props => {
  const {
    rootRef,
    loading,
    InputProps,
    ...other
  } = props

  const classes = useStyles(props)

  return (
    <div ref={rootRef} className={classes.root}>
      <div className={classes.icon}>
        {!loading && <SearchIcon />}
        {loading && <Spinner style={{ height: 24, width: 24 }} />}
      </div>
      <div className={classes.searchfield}>
        <TextField
          {...other}
          fullWidth
          InputProps={{
            ...InputProps,
            classes: { input: classes.inputInput },
            disableUnderline: true
          }}
        />
      </div>
    </div>
  )
}

export default SearchField
