import React, { FC } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { FieldRenderProps } from 'react-final-form';

interface PropsType {
  pickerFormat?: string;
}

const DateTimePickerFormField: FC<PropsType & FieldRenderProps<Date, HTMLElement>> = (props) => {
  const { meta, input, pickerFormat, variant, ...other } = props;
  const { name, onChange, value } = input;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <DateTimePicker
      {...other}
      name={name}
      error={showError}
      value={value || null}
      format={pickerFormat}
      onChange={onChange}
      inputVariant={variant}
      helperText={showError ? meta.error || meta.submitError : undefined}
    />
  );
};

export default DateTimePickerFormField;
