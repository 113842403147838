import React, { FC, useCallback } from 'react';
import createPersistedState from 'use-persisted-state';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import AppNavBar from '@/containers/AppNavBar';
import AppSidebar from '@/containers/AppSidebar';
import AppLoadingModal from '@/containers/AppLoadingModal';
import { useIdentity } from './useIdentity';

const useSidebarOpen = createPersistedState('sidebarOpen');

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      navigation: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 999,
      },
      main: {
        // from theme.mixin.toolbar
        paddingTop: 56,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
          paddingTop: 48,
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: 64,
        },

        transition: 'all 0.3s',
        paddingLeft: 0,
        [theme.breakpoints.up('md')]: {
          paddingLeft: (props: any) => props.sidebarWidth,
        },
      },
    }),
  { name: 'AppLayout' }
);

const AppLayout: FC = (props) => {
  const { children } = props;
  const [sidebarOpen, setSidebarOpen] = useSidebarOpen(false);
  const sidebarWidth = sidebarOpen ? 250 : 56;
  const classes = useStyles({ ...props, sidebarWidth });
  const { orgs } = useIdentity();

  const onToggleSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [setSidebarOpen, sidebarOpen]);

  return (
    <div>
      <AppLoadingModal />
      <div className={classes.navigation}>
        <AppSidebar open={sidebarOpen} width={sidebarWidth} />
        <AppNavBar orgs={orgs} onToggleSidebar={onToggleSidebar} />
      </div>
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default AppLayout;
