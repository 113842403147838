import React, { useState, useEffect, useCallback } from 'react';
import map from 'lodash-es/map';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemText from '@material-ui/core/ListItemText';
import get from 'lodash-es/get';
import gql from 'graphql-tag';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import idx from 'idx';
import Spinner from '@/components/Spinner';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fab from '@material-ui/core/Fab';
import { Theme, IconButton } from '@material-ui/core';
import {
  SearchContentReference as SearchContentReferenceType,
  SearchContentReferenceVariables,
} from '@/types/generated/SearchContentReference';

import ReferenceView from './ReferenceView';
import ReferencePreview from './ReferencePreview';

interface Suggestion {
  label: string;
  value: string;
  description?: string;
}

type NodeLike = {
  draft: any;
  contentId: string;
  repository: string;
  contentType: string;
};

export const SearchContentReference = gql`
  query SearchContentReference($repositories: [String!], $contentTypes: [String!], $query: String) {
    viewer {
      content(repositories: $repositories, contentTypes: $contentTypes, query: $query, first: 50) {
        edges {
          node {
            id
            contentId
            contentType
            repository
            draft
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      search: {
        flex: 1,
      },
      icon: {
        width: theme.spacing(6),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      searchfield: {
        flexGrow: 1,
        position: 'relative',
        boxSizing: 'border-box',
        // paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      inputInput: {
        width: 'auto',
        flexGrow: 1,
        padding: theme.spacing(1.25, 1.25, 1.25, 1),
      },
      listItemText: {
        display: 'inline-flex',
        flexDirection: 'column',
      },
      referenceViewContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      deleteButton: {
        marginLeft: theme.spacing(1),
      },
    }),
  { name: 'ReferenceSearch' }
);

const ReferenceSearch: React.FC<
  FieldComponentProps & { contentTypes?: string[]; locale?: string | undefined }
> = (props) => {
  const { placeholder, locale } = props;

  const classes = useStyles(props);
  const [items, setItems] = useState<Suggestion[]>([]);
  const [inputValue, setInputValue] = useState();

  const getRepository = () => {
    return typeof window !== 'undefined' ? window.location.href.split('/')[5] : '*';
  };

  const { data, loading } = useQuery<SearchContentReferenceType, SearchContentReferenceVariables>(
    SearchContentReference,
    {
      skip: !inputValue,
      variables: {
        query: '*' + inputValue + '*',
        contentTypes: props.contentTypes,
        repositories: props.repositories || [getRepository(), 'global'],
      },
    }
  );

  const onInputChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);

  const onChange = useCallback(
    (e: any, b: any) => {
      if (!loading && b) props.onChange(b.value);
    },
    [loading, props]
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    const nodes = map(
      idx(data, (_) => _.viewer.content.edges),
      (edge) => {
        return edge.node;
      }
    );

    const items = map(nodes, (node) => ({
      value: node.contentId,
      preview: <ReferencePreview locale={locale} node={node} schema={props.schema} />,
    }));

    setItems(items);
  }, [loading, data, props.schema, locale]);

  if (typeof props.value === 'string' && props.value) {
    return (
      <div className={classes.referenceViewContainer}>
        <ReferenceView locale={locale} contentId={props.value} schema={props.schema} />
        <IconButton
          aria-label="delete"
          className={classes.deleteButton}
          onClick={() => props.onChange(undefined)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.search}>
      <Autocomplete
        clearOnEscape
        value={props.value}
        options={items}
        loading={loading}
        filterOptions={(options) => options}
        onClick={props.onClick}
        onChange={onChange}
        noOptionsText="No results have been found"
        getOptionLabel={(item) => get(item, 'value', '')}
        renderOption={(item, state) => (
          <ListItem
            disableGutters
            key={item.value}
            style={{ fontWeight: state.selected ? 500 : 400 }}
            className={classes.listItemText}
            component="div"
          >
            {item.preview}
          </ListItem>
        )}
        renderInput={({ ref, ...params }) => (
          <div ref={ref}>
            <div className={classes.icon}>
              {loading && <Spinner style={{ height: 24, width: 24 }} />}
            </div>
            <div className={classes.searchfield}>
              <TextField
                {...params}
                fullWidth
                onChange={onInputChange}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  classes: { input: classes.inputInput },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ReferenceSearch;
