import React, { FC, useState } from 'react';
import { WindowLocation } from '@reach/router';
import PageLayout from '@/components/PageLayout';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FilledInput from '@material-ui/core/FilledInput';
import Box from '@material-ui/core/Box';
import { Form, Field } from 'react-final-form';
import TextFieldFormField from '@/form-fields/TextField';
import SelectFormField from '@/form-fields/Select';
import startCase from 'lodash-es/startCase';
import lowerCase from 'lodash-es/lowerCase';
import { UpdateContent, UpdateContentVariables } from '@/types/generated/UpdateContent';
import schema from '@/screens/ContentView/schema';
import generateUEID from '@/helpers/randomGenerator';
import { StringParam, useQueryParam } from 'use-query-params';

interface PropsType {
  contentId: string;
  location: WindowLocation;
  contentType: string;
  repository: string;
}

const UPDATE_CONTENT = gql`
  mutation UpdateContent(
    $contentId: String!
    $contentType: String!
    $repository: String!
    $doc: Json!
  ) {
    updateContent(
      contentId: $contentId
      contentType: $contentType
      repository: $repository
      doc: $doc
    )
  }
`;

const NewContentType: FC<PropsType> = (props) => {
  const [updateContent] = useMutation<UpdateContent, UpdateContentVariables>(UPDATE_CONTENT);
  const [type] = useQueryParam('type', StringParam);
  const [loading, setLoading] = useState(false);

  const onSubmit = (doc: any) => {
    const Content = {
      contentId:
        (props.repository === 'global' ? '' : props.repository + '.') +
        doc.contentType +
        '.' +
        doc.contentId,
      contentType: doc.contentType,
      repository: props.repository,
      doc: {},
    };
    updateContent({
      variables: Content,
    });
  };
  const randomUUID = generateUEID();

  const getTypes = (doc: any) => {
    return schema ? schema.find((r) => r.repository === props.repository).types : {};
  };

  return (
    <PageLayout>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, form, values }) => (
          <form onSubmit={handleSubmit}>
            <span>Repository: </span>

            <Box>
              <p>{props.repository}</p>
            </Box>
            <span>Content Type: </span>
            <Box>
              <Field
                fullWidth
                disableUnderline
                name="contentType"
                props={{ input: <FilledInput /> }}
                component={SelectFormField}
                defaultValue={type}
                required
              >
                {Object.values(getTypes(form)).map((value) => (
                  <MenuItem key={value.contentType} value={value.contentType}>
                    {startCase(lowerCase(value.contentType))}
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <span>Content ID: </span>
            <Box>
              <Field
                fullWidth
                initialValue={randomUUID}
                name="contentId"
                margin="normal"
                variant="filled"
                required
                component={TextFieldFormField}
                InputProps={{ disableUnderline: true }}
                placeholder="UUID"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box>
              New ID:{' '}
              {(props.repository === 'global' ? '' : props.repository + '.') +
                values.contentType +
                '.' +
                values.contentId}
            </Box>
            <FormControl margin="normal" fullWidth>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting || !values.contentType}
              >
                SUBMIT
              </Button>
            </FormControl>
          </form>
        )}
      </Form>{' '}
    </PageLayout>
  );
};

export default NewContentType;
