import getControl from './getControl'
import isMarkActive from './isMarkActive';
import isBlockActive from './isBlockActive';
import { Editor } from 'slate';

export default function isControlActive(editor: Editor, id: string): boolean {
  const control = getControl(id)

  if (!control) {
    return false;
  }

  if (control.type === 'format') {
    return isMarkActive(editor, id) || isBlockActive(editor, id);
  }

  return false
}
