import React from 'react';
import { FieldComponent } from '../ContentEditor';
import Grid from '@material-ui/core/Grid';
import NumberFieldFormField from '@/form-fields/NumberField';
import { Field } from 'react-final-form';

const NumberField: FieldComponent = ({ title, name, value, onClick }) => (
  <Field
    fullWidth
    name={name}
    label={title}
    onClick={onClick}
    value={value || ''}
    variant="filled"
    component={NumberFieldFormField}
    InputProps={{ disableUnderline: true }}
    InputLabelProps={{ shrink: true }}
  />
);

export default NumberField;
